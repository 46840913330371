<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="fireFightingLegalCheck"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable && isOld"
              label="삭제"
              icon="delete_forever"
              @btnClicked="deleteInfo" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              required
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="fireFightingLegalCheck.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-datepicker
              required
              :editable="editable"
              default="today"
              type="datetime"
              name="regDtStrTime"
              label="작성일시"
              v-model="fireFightingLegalCheck.regDtStrTime" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-field
              required
              :editable="editable"
              :data="fireFightingLegalCheck"
              deptValue="regDeptCd"
              type="dept_user"
              name="regUserId"
              label="작성자"
              v-model="fireFightingLegalCheck.regUserId" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              required
              :editable="editable"
              :rows="5"
              label="내용" 
              name="contents" 
              v-model="fireFightingLegalCheck.contents" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable"
              label="첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingLegalCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingLegalCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      fireFightingLegalCheck: {
        sopFireFightingLegalCheckId: '',  // 소방설비 법정점검 일련번호
        plantCd: null,  // 사업장코드
        contents: '',  // 내용
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_LEGAL_CHECK',
        taskKey: '',
      },
      mappingType: 'PUT',
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingLegalCheckId)
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingLegalCheck.get.url
      this.saveUrl = transactionConfig.fft.fireFightingLegalCheck.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingLegalCheck.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingLegalCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.fireFightingLegalCheck, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.sopFireFightingLegalCheckId)
          
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.$set(this.fireFightingLegalCheck, 'regUserId', this.$store.getters.user.userId)
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingLegalCheck.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingLegalCheck.insert.url
        this.mappingType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingLegalCheck.regUserId = this.$store.getters.user.userId;
              this.fireFightingLegalCheck.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopFireFightingLegalCheckId) {
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.popupParam.sopFireFightingLegalCheckId = result.data
      this.getDetail();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingLegalCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>