var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.fireFightingLegalCheck,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && _vm.isOld,
                            expression: "editable && isOld",
                          },
                        ],
                        attrs: { label: "삭제", icon: "delete_forever" },
                        on: { btnClicked: _vm.deleteInfo },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.fireFightingLegalCheck.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.fireFightingLegalCheck, "plantCd", $$v)
                        },
                        expression: "fireFightingLegalCheck.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        default: "today",
                        type: "datetime",
                        name: "regDtStrTime",
                        label: "작성일시",
                      },
                      model: {
                        value: _vm.fireFightingLegalCheck.regDtStrTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.fireFightingLegalCheck,
                            "regDtStrTime",
                            $$v
                          )
                        },
                        expression: "fireFightingLegalCheck.regDtStrTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-field", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        data: _vm.fireFightingLegalCheck,
                        deptValue: "regDeptCd",
                        type: "dept_user",
                        name: "regUserId",
                        label: "작성자",
                      },
                      model: {
                        value: _vm.fireFightingLegalCheck.regUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.fireFightingLegalCheck, "regUserId", $$v)
                        },
                        expression: "fireFightingLegalCheck.regUserId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        rows: 5,
                        label: "내용",
                        name: "contents",
                      },
                      model: {
                        value: _vm.fireFightingLegalCheck.contents,
                        callback: function ($$v) {
                          _vm.$set(_vm.fireFightingLegalCheck, "contents", $$v)
                        },
                        expression: "fireFightingLegalCheck.contents",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                        label: "첨부파일",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }